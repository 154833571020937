import * as React from "react";

function SvgWarning(props) {
  return (
    <svg
        width="81"
        height="82"
        viewBox="0 0 81 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <ellipse cx={40.5} cy={41} rx={40.5} ry={41} fill="#000" />
      <mask
        id="warning_svg__a"
        maskUnits="userSpaceOnUse"
        x={33}
        y={24}
        width={15}
        height={33}
        fill="#000"
      >
        <path fill="#fff" d="M33 24h15v33H33z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.607 43.084c-.11.844-.623 1.266-1.466 1.266h-1.246c-.88 0-1.392-.422-1.502-1.266l-1.283-12.94v-.808c0-.844.513-1.336 1.393-1.336h3.994c.917 0 1.393.492 1.393 1.336v.809l-1.283 12.94zM44 49.941C44 51.699 42.46 53 40.482 53 38.466 53 37 51.699 37 49.94c0-1.757 1.466-3.128 3.482-3.128 1.979 0 3.518 1.37 3.518 3.129z"
        />
      </mask>
      <path
        d="M42.607 43.084l3.57.465.007-.054.006-.055-3.583-.356zm-4.214 0l-3.583.355.006.056.007.055 3.57-.466zm-1.283-12.94h-3.6v.179l.018.177 3.582-.355zm6.78 0l3.582.356.018-.177v-.178h-3.6zM41.141 47.95c1.008 0 2.266-.257 3.344-1.177 1.094-.933 1.554-2.163 1.692-3.224l-7.14-.93c.029-.216.177-.813.776-1.324.584-.498 1.163-.545 1.328-.545v7.2zm-1.246 0h1.246v-7.2h-1.246v7.2zm-5.072-4.4c.138 1.062.602 2.306 1.721 3.244 1.094.916 2.36 1.156 3.351 1.156v-7.2c.113 0 .682.03 1.271.524.616.515.768 1.127.797 1.345l-7.14.93zM33.528 30.5l1.282 12.94 7.165-.71-1.283-12.94-7.164.71zm-.018-1.164v.809h7.2v-.809h-7.2zm4.993-4.936c-1.096 0-2.436.315-3.519 1.354-1.103 1.058-1.474 2.421-1.474 3.582h7.2c0 .317-.114 1.012-.741 1.613-.607.582-1.25.651-1.466.651v-7.2zm3.994 0h-3.994v7.2h3.994v-7.2zm4.993 4.936c0-1.088-.32-2.466-1.435-3.558-1.105-1.08-2.478-1.378-3.558-1.378v7.2c-.163 0-.84-.051-1.477-.675-.649-.634-.73-1.345-.73-1.589h7.2zm0 .809v-.809h-7.2v.809h7.2zm-1.3 13.295l1.282-12.94-7.164-.71-1.283 12.94 7.165.71zM40.482 56.6c3.447 0 7.118-2.43 7.118-6.66h-7.2c0-.094.023-.21.076-.323a.653.653 0 01.156-.214c.082-.07.06-.003-.15-.003v7.2zM33.4 49.94c0 4.161 3.519 6.66 7.082 6.66v-7.2c-.237 0-.23-.072-.117.029.056.049.12.127.17.234.05.109.065.21.065.278h-7.2zm7.082-6.728c-3.676 0-7.082 2.67-7.082 6.729h7.2a.613.613 0 01-.056.245.588.588 0 01-.137.194c-.096.088-.11.031.075.031v-7.2zM47.6 49.94c0-4.13-3.557-6.73-7.118-6.73v7.2c.158 0 .172.053.108-.005a.54.54 0 01-.124-.174.703.703 0 01-.066-.291h7.2z"
        fill="#97A1FF"
        mask="url(#warning_svg__a)"
      />
    </svg>
  );
}

export default SvgWarning;
