import * as React from "react";

function SvgBell(props) {
  return (
    <svg
        width="81"
        height="82"
        viewBox="0 0 81 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <ellipse cx={40.5} cy={41} rx={40.5} ry={41} fill="#000" />
      <mask
        id="bell_svg__a"
        maskUnits="userSpaceOnUse"
        x={24.013}
        y={24.422}
        width={33}
        height={32}
        fill="#000"
      >
        <path fill="#fff" d="M24.013 24.422h33v32h-33z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.075 41.529l2.774 3.222a1.19 1.19 0 01-.84 2.032H29.201a1.192 1.192 0 01-.842-2.032l2.723-3.222a2.378 2.378 0 00.697-1.683v-3.09a8.768 8.768 0 019.077-8.328 8.95 8.95 0 018.522 8.676v2.742c0 .631.25 1.237.696 1.683zM40.6 52.775a4.415 4.415 0 01-4.59-3.57h9.182a4.417 4.417 0 01-4.592 3.57z"
        />
      </mask>
      <path
        d="M52.849 44.75l-2.728 2.35.089.103.097.097 2.542-2.55zm-2.774-3.221l2.728-2.349-.087-.102-.096-.095-2.545 2.546zm1.933 5.254l.002-3.6h-.002v3.6zm-22.806 0l-.006 3.6h.006v-3.6zm-1.098-.735l-3.326 1.378 3.326-1.378zm.256-1.297l2.552 2.54.103-.104.094-.112-2.749-2.324zm2.723-3.222l-2.545-2.546-.107.107-.097.115 2.75 2.324zm.697-1.683h-3.6v.002l3.6-.002zm0-3.09l-3.596-.18-.004.09v.09h3.6zm9.077-8.328l.171-3.596h-.02l-.019-.001-.132 3.597zm8.522 8.676h3.6v-.053l-.002-.053-3.598.106zm0 2.742l3.6.002v-.002h-3.6zm-13.369 9.36v-3.6h-4.363l.828 4.283 3.535-.683zm4.59 3.57l.208-3.595-.208-.012-.208.012.208 3.594zm4.592-3.57l3.534.685.83-4.285h-4.364v3.6zm10.385-6.804l-2.773-3.222-5.457 4.697L50.12 47.1l5.456-4.697zm.858 5.022a4.79 4.79 0 00-1.044-5.222L50.307 47.3a2.41 2.41 0 01-.525-2.628l6.653 2.752zm-4.428 2.959a4.79 4.79 0 004.428-2.959l-6.653-2.752a2.41 2.41 0 012.228-1.489l-.003 7.2zm-22.805 0h22.806v-7.2H29.202v7.2zm-4.424-2.957a4.792 4.792 0 004.418 2.957l.013-7.2a2.409 2.409 0 012.22 1.486l-6.65 2.757zm1.03-5.215a4.792 4.792 0 00-1.03 5.215l6.651-2.757a2.408 2.408 0 01-.517 2.621l-5.104-5.079zm2.526-3.006l-2.723 3.222 5.498 4.648 2.724-3.222-5.499-4.648zm-.154.643c0-.324.129-.636.358-.865l5.09 5.092a5.977 5.977 0 001.752-4.232l-7.2.005zm0-3.093v3.09h7.2v-3.09h-7.2zm12.809-11.924c-6.763-.247-12.469 4.986-12.805 11.746l7.192.357a5.168 5.168 0 015.35-4.908l.263-7.195zm11.988 12.167a12.55 12.55 0 00-11.949-12.166l-.341 7.192a5.35 5.35 0 015.093 5.186l7.197-.212zm.002 2.848v-2.742h-7.2v2.742h7.2zm-.358-.863c.229.23.358.54.358.865l-7.2-.005a5.977 5.977 0 001.75 4.232l5.091-5.092zM32.474 49.89a8.015 8.015 0 008.333 6.48l-.416-7.189a.815.815 0 01-.848-.659l-7.069 1.367zm12.717-4.284H36.01v7.2h9.182v-7.2zm-4.8 10.763a8.017 8.017 0 008.334-6.478l-7.068-1.37a.817.817 0 01-.85.66l-.416 7.188z"
        fill="#97A1FF"
        mask="url(#bell_svg__a)"
      />
    </svg>
  );
}

export default SvgBell;
