import * as React from "react";

function SvgPad(props) {
  return (
    <svg
        width="81"
        height="82"
        viewBox="0 0 81 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <ellipse cx={40.5} cy={41} rx={40.5} ry={41} fill="#000" />
      <mask
        id="pad_svg__a"
        maskUnits="userSpaceOnUse"
        x={26}
        y={24}
        width={30}
        height={33}
        fill="#000"
      >
        <path fill="#fff" d="M26 24h30v33H26z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.543 28h18.514c.852 0 1.543.705 1.543 1.575v22.05c0 .87-.69 1.575-1.543 1.575H31.543c-.852 0-1.543-.705-1.543-1.575v-22.05c0-.87.69-1.575 1.543-1.575zm6.171 14.175h6.172c.852 0 1.543-.705 1.543-1.575s-.691-1.575-1.543-1.575h-6.172c-.852 0-1.543.705-1.543 1.575s.691 1.575 1.543 1.575zm6.172-6.3h-6.172c-.852 0-1.543-.705-1.543-1.575s.691-1.575 1.543-1.575h6.172c.852 0 1.543.705 1.543 1.575s-.691 1.575-1.543 1.575zm-6.172 12.6h6.172c.852 0 1.543-.705 1.543-1.575s-.691-1.575-1.543-1.575h-6.172c-.852 0-1.543.705-1.543 1.575s.691 1.575 1.543 1.575z"
        />
      </mask>
      <path
        d="M50.057 24.4H31.543v7.2h18.514v-7.2zm5.143 5.175c0-2.788-2.233-5.175-5.143-5.175v7.2A2.041 2.041 0 0148 29.575h7.2zm0 22.05v-22.05H48v22.05h7.2zM50.057 56.8c2.91 0 5.143-2.387 5.143-5.175H48c0-1.048.851-2.025 2.057-2.025v7.2zm-18.514 0h18.514v-7.2H31.543v7.2zM26.4 51.625c0 2.788 2.233 5.175 5.143 5.175v-7.2c1.206 0 2.057.977 2.057 2.025h-7.2zm0-22.05v22.05h7.2v-22.05h-7.2zm5.143-5.175c-2.91 0-5.143 2.387-5.143 5.175h7.2a2.041 2.041 0 01-2.057 2.025v-7.2zm12.343 14.175h-6.172v7.2h6.172v-7.2zM41.829 40.6c0-1.048.851-2.025 2.057-2.025v7.2c2.91 0 5.143-2.387 5.143-5.175h-7.2zm2.057 2.025a2.041 2.041 0 01-2.057-2.025h7.2c0-2.788-2.233-5.175-5.143-5.175v7.2zm-6.172 0h6.172v-7.2h-6.172v7.2zm2.057-2.025a2.041 2.041 0 01-2.057 2.025v-7.2c-2.91 0-5.143 2.387-5.143 5.175h7.2zm-2.057-2.025c1.206 0 2.057.977 2.057 2.025h-7.2c0 2.788 2.233 5.175 5.143 5.175v-7.2zm0 .9h6.172v-7.2h-6.172v7.2zM32.571 34.3c0 2.788 2.233 5.175 5.143 5.175v-7.2c1.206 0 2.057.977 2.057 2.025h-7.2zm5.143-5.175c-2.91 0-5.143 2.387-5.143 5.175h7.2a2.041 2.041 0 01-2.057 2.025v-7.2zm6.172 0h-6.172v7.2h6.172v-7.2zm5.143 5.175c0-2.788-2.233-5.175-5.143-5.175v7.2a2.041 2.041 0 01-2.057-2.025h7.2zm-5.143 5.175c2.91 0 5.143-2.387 5.143-5.175h-7.2c0-1.048.851-2.025 2.057-2.025v7.2zm0 5.4h-6.172v7.2h6.172v-7.2zM41.829 46.9c0-1.048.851-2.025 2.057-2.025v7.2c2.91 0 5.143-2.387 5.143-5.175h-7.2zm2.057 2.025a2.041 2.041 0 01-2.057-2.025h7.2c0-2.788-2.233-5.175-5.143-5.175v7.2zm-6.172 0h6.172v-7.2h-6.172v7.2zm2.057-2.025a2.041 2.041 0 01-2.057 2.025v-7.2c-2.91 0-5.143 2.387-5.143 5.175h7.2zm-2.057-2.025c1.206 0 2.057.977 2.057 2.025h-7.2c0 2.788 2.233 5.175 5.143 5.175v-7.2z"
        fill="#97A1FF"
        mask="url(#pad_svg__a)"
      />
    </svg>
  );
}

export default SvgPad;
